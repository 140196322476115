import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

import FlexBox from './FlexBox';
import Typography from './Typography/Typography';

import { useIsMobileLayout } from '../../../hooks/useWindowSize';

const BASE_ICON_WIDTH = 42;

const Icon = styled.img`
  position: relative;
  width: ${({ theme, isMobile }) => (!isMobile ? BASE_ICON_WIDTH : theme.conversionRatio * BASE_ICON_WIDTH)}px;
`;

const CardIcon = ({
  title,
  description,
  image,
  style,
  theme,
}) => {
  const isMobile = useIsMobileLayout();
  const { grey2 } = theme.colours;

  return (
    <FlexBox style={style}>
      <div>
        <Icon
          isMobile={isMobile}
          src={image}
          alt={title}
        />
      </div>

      <FlexBox column style={{ marginLeft: 25 }} id='content'>
        <Typography colour={grey2} tag={isMobile ? 'h6' : 'h5'} fontWeight='bold'>
          {title}
        </Typography>
        <Typography colour={grey2} tag={isMobile ? 'psm' : 'pbig'} style={{ marginTop: 15 }}>
          {description}
        </Typography>
      </FlexBox>
    </FlexBox>
  );
};

CardIcon.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  style: PropTypes.object,
  theme: PropTypes.object,
};

CardIcon.defaultProps = {
  style: {},
  theme: {},
};

export default withTheme(CardIcon);
