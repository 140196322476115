import React from 'react'

import LogoBranca from "../../assets/logo/logo-branca.svg";
import LogoPrincipal from "../../assets/logo/logo-principal.svg";





const Footer = (props) => {

    return (
        <footer className="footer-container">
            <div className="container-right">
                <a className="footer-anchor" href="https://suppli.us/inicio/cadastro"><img className="footer-logo" src={LogoBranca}/></a>
            </div>
            <div className="container-left">
                <div className="container-left-anchors">
                    {/* <a className="footer-anchor" href={`https://api.whatsapp.com/send?phone=5585981669135&text=Olá,%0agostaria%20de%20ter%20mais%20informações%20da%20supplius.`}>(85)98166-9135 </a> */}
                    <a className="footer-anchor" href="mailto:contato@supplius.com">contato@supplius.com </a>
                </div>
            </div>
            
        </footer>
    )
}

export default Footer

