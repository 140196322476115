import PropTypes, { object, func } from 'prop-types';

import styled from 'styled-components';

const Flex = styled.div`
  display: flex;
  ${({ column }) => column && 'flex-direction: column;'}
  ${({ jContent }) => jContent && `justify-content: ${jContent};`}
  ${({ aItems }) => aItems && `align-items: ${aItems};`}
  ${({ wrap }) => wrap && 'flex-wrap: wrap;'}
  ${({ aContent }) => aContent && `align-content: ${aContent};`}
  ${({ flex }) => flex && `flex: ${flex};`}
  ${({ aSelf }) => aSelf && `align-self: ${aSelf};`}
  ${({ order }) => order && `order: ${order};`}
`;

Flex.propTypes = {
  column: PropTypes.bool,
  wrap: PropTypes.bool,
  wrapReverse: PropTypes.bool,
  jContent: PropTypes.string,
  flex: PropTypes.string,
  aSelf: PropTypes.string,
  aContent: PropTypes.string,
  aItems: PropTypes.string,
  order: PropTypes.string,
  children: PropTypes.oneOfType([object, func]).isRequired,
};

Flex.defaultProps = {
  column: false,
  wrap: false,
  wrapReverse: false,
  jContent: '',
  flex: '',
  aSelf: '',
  aContent: '',
  aItems: '',
  order: '',
};
export default Flex;
