import React from 'react'
import HandsImage from "../../assets/images/shaking-hands.png";
const Banner1 = () => {
    return (
        <div className="banner-1-container">
            <div className="banner-1-img-container">
            <img className="banner-1-img" src={HandsImage} />
            </div>
            <div className="banner-1-overlay-container">
                <div className="banner-1-overlay-container-left">
                    <div className="banner-1-overlay-container-left-text-container">
                        <p className="banner-1-overlay-container-left-text-container-title">
                            <span>Melhor<b>&nbsp;para comprar</b></span>
                            <span>Melhor<b>&nbsp;para vender</b></span>
                        </p>
                        <p className="banner-1-overlay-container-left-text-container-subtitle">
                            <strong>
                            Plataforma B2B digital de negociações em grupo
                            <br/>
                            Sem custos fixos ou mensalidades
                            </strong>
                        </p>
                        <div className="banner-1-overlay-container-left-text-container-button-container">
                            {/* <a className="banner-1-overlay-container-left-text-container-button" href="https://suppli.us/inicio/cadastro">Experimente Grátis</a> */}
                        </div>
                       
                    </div>
                </div>
                <div className="banner-1-overlay-container-right">
                   
                </div>
            </div>
        </div>
    )
}

export default Banner1;
