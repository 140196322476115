import styled from 'styled-components';
import PropTypes from 'prop-types';

const Text = styled.span`
  position: relative;
  display: ${({ block, display }) => display || (block ? 'block' : 'inline')};
  color: ${({ theme, colour }) => theme.colours[colour] || colour};
  font-size: ${({ fontSize }) => fontSize}px;
  line-height: ${({ lineHeight }) => (typeof lineHeight === 'number' ? `${lineHeight}px` : lineHeight)};
  text-align: ${({ textAlign }) => textAlign};
  margin-left: ${({ marginLeft }) => (typeof marginLeft === 'number' ? `${marginLeft}px` : marginLeft)};
  margin-right: ${({ marginRight }) => (typeof marginRight === 'number' ? `${marginRight}px` : marginRight)};
  margin-bottom: ${({ marginBottom }) => (typeof marginBottom === 'number' ? `${marginBottom}px` : marginBottom)};
  margin-top: ${({ marginTop }) => (typeof marginTop === 'number' ? `${marginTop}px` : marginTop)};
  font-weight: ${({ fontWeight }) => fontWeight};
  user-select: ${({ userSelect }) => userSelect};
  text-transform: ${({ textTransform }) => textTransform};
  cursor: ${({ onClick, cursor }) => (cursor || onClick ? 'pointer' : 'inherit')};

  &:hover {
    text-decoration: ${({ onClick }) => (onClick ? 'underline' : 'none')};
  }
`;

Text.propTypes = {
  colour: PropTypes.string,
  textAlign: PropTypes.string,
  marginBottom: PropTypes.number,
  fontSize: PropTypes.number,
  lineHeight: PropTypes.number,
  fontWeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  marginTop: PropTypes.number,
  display: PropTypes.string,
  userSelect: PropTypes.string,
  textTransform: PropTypes.string,
  cursor: PropTypes.string,
};

Text.defaultProps = {
  colour: '',
  textAlign: 'left',
  fontSize: 12,
  lineHeight: 'unset',
  fontWeight: 'normal',
  block: false,
  disabled: false,
  marginLeft: 0,
  marginRight: 0,
  marginBottom: 0,
  marginTop: 0,
  display: '',
  userSelect: 'auto',
  textTransform: 'none',
  cursor: 'inherit',
};

export default Text;
