import React from 'react'

import HeaderHealth from "./HeaderHealth"
import Banner1Health from "../Banner1Health"
import Banner2Health from "../Banner2Health"
import Banner3Health from "../Banner3Health"
import Banner4Health from "../Banner4Health"
import FooterHealth from "./FooterHealth"

const Main = () => {
    return (
        <div className="main-container">
          
          <HeaderHealth />
          <Banner1Health />
          
          <Banner2Health />
          <Banner3Health />
          {/* <Banner4Health /> */}
          <FooterHealth />
        </div>
    )
}

export default Main;