const darkblue = '#1A1F66';
const darkblue2 = '#111651';
const lightblue = '#99A7CC';
const lightblue2 = '#EFF7FF';
const mediumBlue = '#64689A';
const darkgreen = '#009344';
const lightgreen = '#8CC63F';
const orange = '#F68E32';
const lightorange = '#F68E32';
const grey = '#C5C5C5';
const grey2 = '#4F4F4F';
const grey3 = '#828282';
const grey4 = '#BDBDBD';
const grey5 = '#E0E0E0';
const grey6 = '#F2F2F2';
const grey7 = '#E5E5E5';
const lightgrey = '#FDFDFD';

export default {
  conversionRatio: 3.06, // 980 / 320
  colours: {
    darkblue,
    darkblue2,
    lightblue,
    lightblue2,
    darkgreen,
    lightgreen,
    lightgrey,
    mediumBlue,
    orange,
    grey,
    grey2,
    grey3,
    grey4,
    grey5,
    grey6,
    grey7,
    lightorange,
    tableStrip: '#FAFAFA',
    icons: lightblue,
    background: '#f3f3f3',
    white: '#FFFFFF',
    error: '#EB5757',
    danger: '#EB5757',
    success: lightgreen,
    warning: orange,
    disabled: grey7,
    disabledFont: grey4,
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.08)',
    status: {
      danger: '#ED7373',
      warning: '#ED7373',
      success: '#A1E09B',
      accepted: '#DBFFD2',
      pending: '#FFEEC4',
      isRejected: '#FFE1E1',
      rejected: '#FFE1E1',
      disabled: grey6,
    },
  },

  fonts: {
    regular: 'Montserrat-Regular',
    bold: 'Montserrat-Bold',
    semiBold: 'Montserrat-SemiBold',
    semibold: 'Montserrat-SemiBold',
    light: 'Montserrat-Light',
    medium: 'Montserrat-Medium',
  },
};
