import React, { useState, useEffect, useCallback } from 'react'

import LogoBranca from "../../assets/logo/logo-branca.svg";
import LogoPrincipal from "../../assets/logo/logo-principal.svg";





const Header = (props) => {

    
    const [hasScrolled, setHasScrolled] = useState(false);
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    
    const scrollTo = (id) => {
        const yourElement = document.getElementById(id);
        const y = yourElement.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    };


   
    const onHandleMenuClick = () => {
        setIsMenuOpened(!isMenuOpened);
       
    }


    const onScroll = useCallback(() => {
        if (window.pageYOffset > 0) {
          setHasScrolled(true);
        }
    
        else {
          setHasScrolled(false);
        }
    
        setIsMenuOpened(false);
      }, []);
    
      useEffect(() => {
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
      }, [onScroll]);

    return (
        <header className={hasScrolled ? "header-container header-container-white" : "header-container header-container-transparent"}>
            <div className="container-right">
                <a className="header-anchor" href="/"><img className="header-logo" src={hasScrolled ?  LogoPrincipal : LogoBranca}/></a>
            </div>
            <div className="container-left">
                
            <div onClick={onHandleMenuClick} className="hamburger-menu-clickable">
                    {isMenuOpened ?(<div className={hasScrolled ? "hamburger-close-button-dark" : "hamburger-close-button" }>X</div>) : (
                    <div className="hamburger-menu">
                    <div className={hasScrolled ? "hamburger-stripe-dark" : "hamburger-stripe" }></div> 
                    <div className={hasScrolled ? "hamburger-stripe-dark" : "hamburger-stripe" }></div> 
                    <div className={hasScrolled ? "hamburger-stripe-dark" : "hamburger-stripe" }></div> 
            
                    </div>
                    ) 
                }
                </div>
            

                {/* <div className="container-left-anchors">
                    <a className="header-anchor-button" href="https://supplius.com/cadastro">Cadastro</a>
                    <a className="header-anchor-button" href="https://supplius.com/login">Login</a>
                </div> */}
            </div>
            
                    
                    <div className={`header-bottom-container ${isMenuOpened ? "header-bottom-container-opened" : null} ${hasScrolled ?  "header-bottom-container-white" : "header-bottom-container-transparent"  }`}>
                       
                        
                            <div className="container-left-anchors">
                                <a className={`header-anchor ${isMenuOpened ? "header-anchor-show " : null} ${hasScrolled ?  "header-anchor-black" : "header-anchor-white"}`} href="https://supplius.com/cadastro">Cadastro</a>
                                <a className={`header-anchor ${isMenuOpened ? "header-anchor-show " : null} ${hasScrolled ?  "header-anchor-black" : "header-anchor-white"}`} href="https://supplius.com/login">Login</a>
                            </div>
                          
                    </div>
              
        </header>
    )
}

export default Header

