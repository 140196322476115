import React from 'react'
import SnobDoctorPrick from "../../assets/images/snob-doctor.png";


const Banner1Health = () => {
    return (
        <div className="banner-1-health-container">
                <div className="banner-1-container-left">
                    <div className="banner-1-container-left-text-container">
                        <p className="banner-1-container-left-text-container-title">
                            <span>Melhor<b>&nbsp;para comprar</b></span>
                            <span>Melhor<b>&nbsp;para vender</b></span>
                        </p>
                        <p className="banner-1-container-left-text-container-subtitle">
                           
                            Participe dessa inovadora rede de compras
                            
                        </p>
                        <div className="banner-1-container-left-text-container-button-container">
                            <a className="banner-1-container-left-text-container-button" href="https://saude.suppli.us/inicio/cadastro">Experimente</a>
                        </div>
                       
                    </div>
                </div>
                <div className="banner-1-container-right">
                   <img className="banner-1-health-img" src={SnobDoctorPrick} />
                </div>
           
        </div>
    )
}

export default Banner1Health
