import React from 'react'
import Banner1 from "../banners/Banner1";
import Banner2 from "../banners/Banner2";
import Banner3 from "../banners/Banner3";


import Footer from "./Footer"


import Banner1Sectors from "../banners/Banner1Sectors"
import Header from './Header';
const Main = () => {
    return (
        <div className="main-container">
          <Header />
          <Banner1 />
          <Banner1Sectors />
          <Banner2 />
          <Banner3 />
          <Footer />
        </div>
    )
}

export default Main;