import React from 'react'

import HandHoldingUSD from "../../assets/images/hand-holding-usd.png";
import RunningMan from "../../assets/images/running-man.png";
import CrossedArrows from "../../assets/images/crossed-arrows.png";
import Forbbiden from "../../assets/images/forbbiden.png";
import WatchCircle from "../../assets/images/watch-circle.png";
import MoneyBaloon from "../../assets/images/money-baloon.png";
import Locker from "../../assets/images/locker.png";
import Binoculars from "../../assets/images/binoculars.png";


const Banner3 = () => {
    return (
        <>
        <div className="banner-3-container">

            <div className="banner-3-section-container">
                <div className="banner-3-section-title">
                    Compradores
                </div>
                <div className="banner-3-section-items-container">
                    
                    <div className="banner-3-section-item">
                        <div className="banner-section-item-container">
                            <div className="banner-3-section-item-header">
                                <img className="banner-3-section-item-header-icon" src={HandHoldingUSD} />

                                <div className="banner-3-section-item-header-title">
                                    MELHOR<br/>PREÇO
                                </div>

                            </div>
                            <div className="banner-3-section-item-content">
                                Junte-se a outras empresas para fazer compras maiores
                            </div>
                        </div>
                        
                    </div>

                    <div className="banner-3-section-item">
                        <div className="banner-section-item-container">
                            <div className="banner-3-section-item-header">
                                <img className="banner-3-section-item-header-icon"  src={RunningMan}/>
                                <div className="banner-3-section-item-header-title">MAIS<br/>AGILIDADE</div>
                            </div>
                            <div className="banner-3-section-item-content">
                            Acesso às melhores condições sem necessidade de cotações

                            </div>
                        </div>
                    </div>

                    <div className="banner-3-section-item">
                        <div className="banner-section-item-container">
                            <div className="banner-3-section-item-header">
                                <img className="banner-3-section-item-header-icon"  src={CrossedArrows}/>
                                <div className="banner-3-section-item-header-title">MAIS<br/>OPÇÕES</div>
                            </div>
                            <div className="banner-3-section-item-content">
                                Vários fornecedores em um só lugar com promoções imbatíveis
                            </div>
                        </div>
                    </div>

                    <div className="banner-3-section-item">
                        <div className="banner-section-item-container">
                            <div className="banner-3-section-item-header">
                                <img className="banner-3-section-item-header-icon" src={Forbbiden} />
                                <div className="banner-3-section-item-header-title">MENOS<br/>BUROCRACIA</div>
                            </div>
                            <div className="banner-3-section-item-content">
                            Economize tempo  comprando de forma rápida, transparente e segura
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

            <div className="banner-3-section-container">
                <div className="banner-3-section-title">
                    Vendedores
                </div>
                <div className="banner-3-section-items-container">
                    
                    <div className="banner-3-section-item">
                        <div className="banner-section-item-container">
                            <div className="banner-3-section-item-header">
                                <img className="banner-3-section-item-header-icon"  src={WatchCircle}/>
                                <div className="banner-3-section-item-header-title">MAIS<br/>AGILIDADE</div>
                            </div>
                            <div className="banner-3-section-item-content">
                                Grupos dinâmicos prontos para comprar organizados por algoritmos
                            </div>
                        </div>
                    </div>

                    <div className="banner-3-section-item">
                        <div className="banner-section-item-container">
                            <div className="banner-3-section-item-header">
                                <img className="banner-3-section-item-header-icon"  src={MoneyBaloon}/>
                                <div className="banner-3-section-item-header-title">MENOS<br/>CUSTOS</div>
                            </div>
                            <div className="banner-3-section-item-content">
                            A Demanda agrupada permite planejamento de produção e otimização logística
                            </div>
                        </div>
                    </div>

                    <div className="banner-3-section-item">
                        <div className="banner-section-item-container">
                            <div className="banner-3-section-item-header">
                                <img className="banner-3-section-item-header-icon"  src={Locker}/>
                                <div className="banner-3-section-item-header-title">MAIS<br/>SEGURANÇA</div>
                            </div>
                            <div className="banner-3-section-item-content">
                            Preços das ofertas são vinculados ao volume até que o negócio seja fechado
                            </div>
                        </div>
                    </div>

                    <div className="banner-3-section-item">
                        <div className="banner-section-item-container">
                            <div className="banner-3-section-item-header">
                                <img className="banner-3-section-item-header-icon"  src={Binoculars}/>
                                <div className="banner-3-section-item-header-title">MAIOR<br/>ALCANCE</div>
                            </div>
                            <div className="banner-3-section-item-content">
                                Grandes  vendas de uma só vez para várias empresas  ao mesmo tempo
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            
           

        </div>
       
        </>
    )
}

export default Banner3;
