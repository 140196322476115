import React from 'react'

const GeneralButton = (props) => {
    return (
        <a href={props.href} className="general-button-container" style={{backgroundColor: props.background, color: props.color ? props.color : "white", boxShadow: props.withShadow ? "0px 2px 10px 4px rgba(0,0,0,0.2)" : " " }}>
            {props.text}
        </a>
    )
}

export default GeneralButton
