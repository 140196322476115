import React from 'react';

import PropTypes from 'prop-types';

import styled, { withTheme } from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';


import whiteLogo from '../../assets/logo/logo-branca.svg';
import imgGrafismo from '../../assets/images/grafismo.png';


import facebookIcon from '../../assets/social/facebook.svg';
import instagramIcon from '../../assets/social/instagram.svg';
import linkedinIcon from '../../assets/social/linkedin.svg';
import bar from '../../assets/social/divisor.svg';


import Button from '../Button';
import FlexBox from '../FlexBox';
import Typography from '../Typography/Typography';

import linkTo from '../../../../config/link-supplius';
import { useIsMobileLayout } from '../../../../hooks/useWindowSize';

const BackgroundGreen = styled.div`
  width: 100%;
  background: linear-gradient(180deg, #01c401 0%, #8cc63f 100%), #5ab51e;
  background-image: url(${({ image }) => image});

  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: ${({ isMobile }) => (isMobile ? '550' : '290')}px;

  padding: 352px  117px 587px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  .btn{ 
    width: 313px; 
    margin-top: 45px;
  }

  @media only screen and (max-device-width: 992px) {
    .btn{ 
      width: 575px;
      height: 120px;
      border-radius: 20px;
      font-size: 42px;
      margin-top: 145px;
    }
  }
`;


const BackgroundBlue = styled.div`
  background: ${({ background }) => background};

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 65px;

  @media only screen and (max-device-width: 992px) {
    padding: 125px;
  }
`;


const LinksBox = styled.div`
  margin: 35px auto;
  height: 90px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-device-width: 992px) {
    flex-direction: column-reverse;

    #divisor{
      display: none;
    }
  } 
`;

const ThirdFold = ({ theme }) => {
  const isMobile = useIsMobileLayout();

  const {
    colours: {
      white, lightorange, darkblue, darkblue2,
    },
  } = theme;

  return (
    <FlexBox column>
      <BackgroundGreen image={imgGrafismo} isMobile={isMobile}>
        <div style={{ textAlign: 'center' }}>
          <Typography tag={isMobile ? 'h3' : 'h1'} colour={white} fontWeight='400'>
            <span>Experimente</span>
            {' '}
            <span>a plataforma</span>
            <br />
            {' '}
            <strong>e comprove</strong>
            {' '}
            <strong>as vantagens.</strong>
          </Typography>
        </div>
        <a href={linkTo.cadastro}>
          <Button
            className='btn'
            background={lightorange}
            color={white}
          >
            Acesse agora
          </Button>
        </a>
      </BackgroundGreen>
      <BackgroundBlue background={darkblue}>
        <img src={whiteLogo} alt='logo supplius' style={{ width: 334 }} />
        <LinksBox>
          <span>
            <a
              href={linkTo.contact}
            >
              <FontAwesomeIcon
                size='2x'
                color={white}
                icon={faEnvelope}
              />
              <Typography
                tag={isMobile ? 'pmd' : 'h3'}
                colour={white}
                fontWeight='bold'
                style={{ fontSize: '2em', marginLeft: 5 }}
              >
                Entre em contato
              </Typography>
            </a>
          </span>
         
        </LinksBox>
        <div>
          <a href={linkTo.linkedin}>
            <img src={linkedinIcon} alt='linkedin supplius' style={{ width: isMobile ? 66 : 34 }} />
          </a>
          <a href={linkTo.facebook}>
            <img src={facebookIcon} alt='facebook supplius' style={{ marginLeft: 24, width: isMobile ? 66 : 34 }} />
          </a>
          <a href={linkTo.instagram}>
            <img src={instagramIcon} alt='instagram supplius' style={{ marginLeft: 24, width: isMobile ? 66 : 34 }} />
          </a>
        </div>
      </BackgroundBlue>
      <FlexBox
        jContent='center'
        style={{ background: darkblue2, padding: 45 }}
      >
        <Typography
          tag={isMobile ? 'psm' : 'pbig'}
          colour={white}
        >
          Todos os direitos reservados®
        </Typography>
      </FlexBox>
    </FlexBox>
  );
};

ThirdFold.propTypes = {
  theme: PropTypes.object.isRequired,
  colours: PropTypes.object,
  lightorange: PropTypes.string,
  white: PropTypes.string,
  darkblue: PropTypes.string,
  darkblue2: PropTypes.string,
};

ThirdFold.defaultProps = {
  colours: {},
  lightorange: 'white',
  white: 'white',
  darkblue: 'white',
  darkblue2: 'white',
};

export default withTheme(ThirdFold);
