import {
  createGlobalStyle,
} from 'styled-components';

export default createGlobalStyle`  
  html, body, #root {
    background: #e5e5e5;
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
/* 
    @media only screen and (max-device-width: 992px) {
      font-size: 18pt;
    } */
  }

  button {
    cursor: pointer;  

    &:focus {
      outline: 0;
    }
  }

  ul {
    list-style: none;
  }

  input {
    cursor: pointer;
    outline: none;
  }

  a {
    padding: 0;
    margin: 0;
    text-decoration: none;
    color: inherit;
    cursor: pointer;

    &:hover {
      text-decoration: none;
      color: inherit;
    }
  }

  .container-fluid {
    /* padding: 0; */

    /* .row {
      padding: 0;
      margin-left: 0px;
      margin-right: 0px;
    } */
  }
`;
