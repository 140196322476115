import React from 'react';
import Section from './Section';

import buyerImage from '../../assets/images/compradores.png';
import sellerImage from '../../assets/images/fornecedores.png';

import iconPrice from '../../assets/icons/icone-preco.svg';
import iconAgility from '../../assets/icons/icone-agil.svg';
import iconPossibility from '../../assets/icons/icone-possib.svg';
import iconBureaucracy from '../../assets/icons/icone-buro.svg';

import iconBuy from '../../assets/icons/icone-venda.svg';
import iconList from '../../assets/icons/icone-lista.svg';
import iconLock from '../../assets/icons/icone-cadeado.svg';
import iconSpeaker from '../../assets/icons/icone-autofalante.svg';

const buyerSection = {
  title: 'Para compradores',
  colour: 'lightgreen',
  image: buyerImage,
  backgroundColour: '#f3f3f3',
  cards: [
    {
      icon: iconPrice,
      title: 'Garanta o melhor preço',
      description: 'Junte-se a outras construturas e faça pedidos maiores, garantindo preços menores.',
    },
    // {
    //   icon: iconPossibility,
    //   title: 'Amplie suas possibilidades',
    //   description: 'Veja nosso catálogo de amplos fornecedores de todo Brasil em um só lugar.',
    // },
    {
      icon: iconAgility,
      title: 'Feche seus negócios com mais agilidade',
      description: 'Tenha acesso às melhores condições do mercado sem necessidade de fazer várias cotações.',
    },
    {
      icon: iconBureaucracy,
      title: 'Reduza as burocracias',
      description: 'Economize tempo da sua equipe, permitindo uma compra totalmente online, transparente e segura.',
    },
  ],
};

const sellerSection = {
  title: 'Para fornecedores',
  colour: 'darkgreen',
  image: sellerImage,
  backgroundColour: 'white',
  cards: [
    {
      icon: iconBuy,
      title: 'Agilize o fechamento das vendas',
      description: 'Feche contratos de forma ágil e segura, totalmente online e sem burocracias.',
    },
    // {
    //   icon: iconLock,
    //   title: 'Tenha seu melhor preço protegido',
    //   description: 'Dê seu preço campeão de forma segura, onde seus concorrentes não terão acesso.',
    // },
    {
      icon: iconList,
      title: 'Melhore seu planejamento de produção',
      description: 'Saiba quanto deverá produzir para atender as demandas com antecedência e precisão.',
    },
    {
      icon: iconSpeaker,
      title: 'Aumente seu share de mercado',
      description: 'Tenha seus produtos expostos para possíveis clientes em todo Brasil. ',
    },
  ],
};

const SecondFold = () => (
  <>
    <Section id='para-compradores' sections={buyerSection} />
    <Section id='para-fornecedores' hasBorder reverse sections={sellerSection} />
  </>
);

export default SecondFold;
