
import './styles/App.scss';
import { Route,  Switch } from "react-router-dom";

import MainHealth from "./pages/Health/HealthLayout/MainHealth";
import Main from "./pages/mainLayout/Main";
import MainConstruction from "./pages/Construction/pages/main/MainConstruction"

function App() {
  return (
    <div className="App">
      <Switch >
        <Route exact path="/">
          <Main />
        </Route>
        <Route exact path="/saude">
          <MainHealth />
        </Route>
        <Route exact path="/construcao">
          <MainConstruction  />
        </Route>
        <Main />

      </Switch>
    </div>
  );
}

export default App;
