import React from 'react'

import Doctors2 from "../../assets/images/doctors-2.png";


const Banner2Health = () => {
    return (
        <div  className="banner-2-health-container">
                 <div className="banner-2-health-container-left">
                   <img className="banner-2-health-img" src={Doctors2} />
                </div>
                <div className="banner-2-container-right">
                    <div className="banner-2-health-title">
                        Vantagens
                    </div>
                    <div className="banner-2-content-container">
                        <p><b>Redução de custos</b> de produtos de alto valor agregado</p>
                        <p><b>Automação de compra</b> de produtos recorrentes</p>
                        <p>Construção de banco de dados do setor para <b>otimizar logistica</b></p>
                        <p>Relatórios dos <b>ganhos da demanda agrupada</b></p>
                    </div>
                </div>
        </div>
    )
}

export default Banner2Health
