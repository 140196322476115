import React from 'react'
import BusinessMan from "../../assets/images/happy-asian-and-nordic.png";
const Banner2 = () => {
    return (
        <div className="banner-2-container">
             <div className="banner-2-container-left">
                 <div className="banner-2-container-left-text">
                     <div className="banner-2-conainer-left-margin-top">
                     
                     </div>
                     <div className="banner-2-container-left-content">
                     <div className="banner-2-conainer-left-title">
                        Quem Somos
                     </div>
                        <p><b>Plataforma de fechamento de negócio em grupo</b>, não de cotação.</p>
                        <p>Uma forma inovadora e transformadora de <b>comprar e vender</b> com a Supplius.</p>
                        <p>Na Supplius, compradores e fornecedores
                        viram parceiros co-criando soluções</p>
                        <p><b>Anos de experiência em negociações cooperativas aliadas a inteligência da plataforma digital</b></p>
                     </div>
                 </div>
             </div>
             <div className="banner-2-container-right">
                 <div className="banner-2-container-right-img-container">
                     <img src={BusinessMan} className="banner-2-container-right-img" />
                 </div>
             </div>
        </div>
    )
}

export default Banner2;
