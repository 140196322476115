import React from 'react';
import PropTypes from 'prop-types';

import Typography from '../Typography/Typography';
import { useIsMobileLayout } from '../../../../hooks/useWindowSize';

const Title = ({ children, colour }) => {
  const isMobile = useIsMobileLayout();

  return (
    <Typography
      className='title'
      tag={isMobile ? 'h4' : 'h2'}
      colour={colour}
      fontWeight='800'
    >
      {children}
    </Typography>
  );
};

Title.propTypes = {
  children: PropTypes.string.isRequired,
  colour: PropTypes.string,
};

Title.defaultProps = {
  colour: 'lightgreen',
};

export default Title;
