import React from 'react'


import LogoBranca from "../../../assets/logo/logo-branca.svg";

const FooterHealth = () => {
    return (
        <div className="footer-health-container">

            <svg className="footer-svg-background" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path fill="#1A1F66"  fill-opacity="1" d="M0,192L48,160C96,128,192,64,288,69.3C384,75,480,149,576,186.7C672,224,768,224,864,197.3C960,171,1056,117,1152,90.7C1248,64,1344,64,1392,64L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
            </svg>
            
            <div className="footer-health-overlay-container">
                <div className="footer-health-container-left">
                    <div className="footer-health-logo-container">
                       <img src={LogoBranca} alt="" className="footer-health-logo"/>
                   </div>
                </div>
                <div className="footer-health-container-right">
                    <ul className="footer-contact-anchors-list">
                        {/* <li className="footer-health-contact-anchor">(85)98166-9135</li>
                        <li className="footer-health-contact-anchor-separator" > &nbsp;| &nbsp; </li> */}
                        <li className="footer-health-contact-anchor">contato@supplius.com</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default FooterHealth
