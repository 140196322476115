import React from 'react';

import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import FlexBox from './FlexBox';

const Container = styled.div`
  cursor: pointer;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: #8cc63f;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 90;

  margin: -32px auto ;

  &:hover {
    font-weight: bold;
    transition: 0.3s;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
    svg {
      color: #eaeaea;
    }
  }
`;

const Divider = () => (
  <FlexBox jContent='center'>
    <Container>
      <FontAwesomeIcon
        color='#fff'
        size='3x'
        icon={faChevronDown}
      />
    </Container>
  </FlexBox>
);

export default Divider;
