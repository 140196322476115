import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import { useIsMobileLayout } from '../../../../hooks/useWindowSize';

import Text from './Text';

export const h1 = (theme, isMobile) => ({
  fontSize: 56 * (isMobile ? theme.conversionRatio : 1),
});

export const h2 = (theme, isMobile) => ({
  fontSize: 42 * (isMobile ? theme.conversionRatio : 1),
});

export const h3 = (theme, isMobile) => ({
  fontSize: 28 * (isMobile ? theme.conversionRatio : 1),
});

export const h3Mobile = (theme, isMobile) => ({
  fontSize: 24 * (isMobile ? theme.conversionRatio : 1),
});

export const h4 = (theme, isMobile) => ({
  fontSize: 20 * (isMobile ? theme.conversionRatio : 1),
});

export const h5 = (theme, isMobile) => ({
  fontSize: 18 * (isMobile ? theme.conversionRatio : 1),
});

export const h6 = (theme, isMobile) => ({
  fontSize: 14 * (isMobile ? theme.conversionRatio : 1),
});

export const pbig = (theme, isMobile) => ({
  fontSize: 18 * (isMobile ? theme.conversionRatio : 1),
});

export const p = (theme, isMobile) => ({
  fontSize: 16 * (isMobile ? theme.conversionRatio : 1),
});

export const pmd = (theme, isMobile) => ({
  fontSize: 12 * (isMobile ? theme.conversionRatio : 1),
});

export const psm = (theme, isMobile) => ({
  fontSize: 10 * (isMobile ? theme.conversionRatio : 1),
});

export const typography = {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h3m: h3Mobile,
  H1: h1,
  H2: h2,
  H3: h3,
  H4: h4,
  H5: h5,
  H6: h6,
  p,
  pmd,
  psm,
  pbig,
};

const Typography = ({
  tag,
  style,
  colour,
  children,
  link,
  theme,
  ...props
}) => {
  const isMobile = useIsMobileLayout();

  return (
    <Text
      colour={colour}
      style={{
        ...(typography[tag] ? typography[tag](theme, isMobile) : {}),
        ...(link ? { textDecorationLine: 'underline' } : ''),
        ...style,
      }}
      {...props}
    >
      {children}
    </Text>
  );
};

Typography.propTypes = {
  tag: PropTypes.string,
  style: PropTypes.object,
  theme: PropTypes.object.isRequired,
  colour: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.any,
  link: PropTypes.bool,
};

Typography.defaultProps = {
  tag: 'p',
  colour: 'darkblue',
  style: {},
  children: '',
  link: false,
};

export default withTheme(Typography);
