import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';

import styled, { withTheme } from 'styled-components/macro';

import {
  Collapse,
  Navbar as RNavbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

import PropTypes from 'prop-types';

import logo from '../../assets/logo/logo-principal.svg';
import logoBranca from '../../assets/logo/logo-branca.svg';

import Button from '../Button';
import Typography from '../Typography/Typography';

import { useIsMobileLayout } from '../../../../hooks/useWindowSize';

import linkTo from '../../../../config/link-supplius';

const Header = styled(RNavbar)`
  position: fixed;
  width: 100%;
  padding: 20px 32px 20px 74px;
  box-sizing: border-box;
  box-shadow: ${({ bgc }) => (bgc === 'transparent' ? 'none' : '0px 4px 4px rgba(0, 0, 0, 0.25)')};
  background-color: ${({ bgc }) => bgc};
  z-index: 100;
  transition: all 250ms ease-in-out;

  .navbar-toggler {
    transform: scale(1.5);
  }

  .nav-item {
    list-style: none;
    padding: 8px 0;
  }

    /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (max-device-width: 992px) {
    padding: 36px 52px;

    .nav-item {
      padding: ${({ isMobile }) => (isMobile ? '36px 0px' : '8px 0px')};
      text-align: center;
      list-style: none;
      border-bottom: solid 3px lightgrey;
    }
  }
`;

const Navbar = ({ theme }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const isMobile = useIsMobileLayout();

  const scrollTo = (id) => {
    const yourElement = document.getElementById(id);
    const y = yourElement.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  const onScroll = useCallback(() => {
    if (window.pageYOffset > 0) {
      setHasScrolled(true);
    }

    else {
      setHasScrolled(false);
    }

    setIsOpen(false);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const { colours: { darkblue, white, lightgreen } } = theme;

  const buttonStyle = {
    mobile: {
      padding: '29px 36px',
      width: 465,
      borderRadius: 12,
      fontSize: 42,
    },
    default: {
      padding: '10px 12px',
      width: 164,
      borderRadius: 5,
    },
  };

  return (
    <Header
      bgc={isOpen || hasScrolled ? 'white' : 'transparent'}
      expand='lg'
      light={isOpen || hasScrolled}
      dark={!isOpen && !hasScrolled}
      style={isOpen ? { borderBottomLeftRadius: 20, borderBottomRightRadius: 20 } : {}}
    >
      <NavbarBrand>
        <img
          src={isOpen || hasScrolled ? logo : logoBranca}
          alt='supplius logo'
        />
      </NavbarBrand>

      <NavbarToggler onClick={toggle} style={{ fontSize: '2em', border: 'none' }} />
      <Collapse style={{justifyContent: "space-between"}}isOpen={isOpen} navbar>
        <Nav className='mr-auto' navbar>
          <NavItem>
            <NavLink>
              <Typography
                tag='p'
                colour={isOpen || hasScrolled ? darkblue : white}
                fontWeight='600'
                onClick={() => scrollTo('para-compradores')}
              >
                Para compradores
              </Typography>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink>
              <Typography
                tag='p'
                colour={isOpen || hasScrolled ? darkblue : white}
                fontWeight={600}
                onClick={() => scrollTo('para-fornecedores')}
              >
                Para fornecedores
              </Typography>
            </NavLink>
          </NavItem>
        </Nav>
        
        <Nav>
        <NavItem isMobile={isMobile}>
          <a
            href={linkTo.cadastro}
          >
            <Button
              color='white'
              background={lightgreen}
              style={buttonStyle[isMobile ? 'mobile' : 'default']}
            >
              CADASTRO
            </Button>
          </a>
        </NavItem>
        <NavItem style={{ borderBottom: 'none' }}>
          <a href={linkTo.login}>
            <Button
              background='transparent'
              color={darkblue}
              style={buttonStyle[isMobile ? 'mobile' : 'default']}
            >
              LOGIN
            </Button>
          </a>
        </NavItem>
        </Nav>
      </Collapse>
    </Header>
  );
};

Navbar.propTypes = {
  theme: PropTypes.object.isRequired,
  colours: PropTypes.object,
  darkblue: PropTypes.string,
  white: PropTypes.string,
  lightgreen: PropTypes.string,
};

Navbar.defaultProps = {
  colours: {},
  darkblue: 'white',
  white: 'white',
  lightgreen: 'white',
};

export default withTheme(Navbar);
