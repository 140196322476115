import React from 'react'
import ClockBlack from "../../assets/images/clock-black.png"
import HandHoldingUSDBlack from "../../assets/images/hand-holding-usd-black.png";
import HandHoldingUSD from "../../assets/images/hand-holding-usd.png";
import RunningMan from "../../assets/images/running-man.png";
import RunningManBlack from "../../assets/images/running-man-black.png";
import CrossedArrows from "../../assets/images/crossed-arrows.png";
import CrossedArrowsBlack from "../../assets/images/arrows-crossed-black.png";
import Forbbiden from "../../assets/images/forbbiden.png";
import WatchCircle from "../../assets/images/watch-circle.png";
import MoneyBaloon from "../../assets/images/money-baloon.png";
import Locker from "../../assets/images/locker.png";
import Binoculars from "../../assets/images/binoculars.png";


const Banner3Health = () => {
    return (
        <div  className="banner-3-health-container">
            <div className="banner-3-health-section">
                <div className="banner-3-health-title">
                    Compradores
                </div>
                <div className="banner-3-health-content">
                    <div className="banner-3-health-backpattern-container">
                        <svg className="banner-3-health-backpattern" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.6">
                            <path d="M48.7873 201.115C32.4021 193.886 20.4569 185.88 13.4244 177.793C-40.3806 115.672 108.92 49.891 301.877 26.6403C451.56 8.59594 624.573 13.2722 773.591 39.2393C867.972 55.6871 942.243 80.1009 970.08 107.788C982.625 120.242 985.736 133.369 977.068 146.732C963.394 167.751 882.649 178.479 806.439 182.237C730.271 186.003 637.855 194.727 565.691 201.662C475.829 210.296 397.212 225.951 301.059 230.503C243.599 233.221 179.962 231.566 126.848 222.774C94.9251 217.478 68.602 209.855 48.7873 201.115Z" fill="#009344"/>
                            </g>
                        </svg>
                    </div>
                    <div className="banner-3-health-grid-items-container">
                        <div className="banner-3-health-grid-item">
                            <div className="grid-3-health-icon-container">
                                <img className="banner-3-section-item-header-icon"style={{color: "#333"}} src={CrossedArrowsBlack} />
                            </div>
                            <div className="grid-3-health-text">
                            Faça parte de uma rede aonde os <b>melhores preços</b> são <b>compartilhados</b> com você
                            </div>
                        </div>

                        <div className="banner-3-health-grid-item">
                            <div className="grid-3-health-icon-container">
                                <img className="banner-3-section-item-header-icon"  src={RunningManBlack}/>
                            </div>
                            <div className="grid-3-health-text">
                            Demanda agrupada leva a <b>redução de custos</b> de produtos de alto valor agregado
                            </div>
                        </div>

                        <div className="banner-3-health-grid-item">
                            <div className="grid-3-health-icon-container">
                                <img className="banner-3-section-item-header-icon"  src={ClockBlack}/>
                            </div>
                              <div className="grid-3-health-text">
                              <b>Automação de compra</b> de produtos recorrentes com informações de mercado 
                            </div>
                        </div>

                        <div className="banner-3-health-grid-item">
                            <div className="grid-3-health-icon-container">
                                <img className="banner-3-section-item-header-icon"  src={HandHoldingUSDBlack}/>
                            </div>
                            <div className="grid-3-health-text">
                            Ganhe o <b>poder de escala das maiores empresas</b> do seu setor
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="banner-3-health-section-vendor">
                <div className="banner-3-health-title">
                    Vendedores
                </div>
                <div className="banner-3-health-content">
                    <div className="banner-3-health-backpattern-container">
                        <svg className="banner-3-health-backpattern" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.6">
                            <path d="M48.7873 201.115C32.4021 193.886 20.4569 185.88 13.4244 177.793C-40.3806 115.672 108.92 49.891 301.877 26.6403C451.56 8.59594 624.573 13.2722 773.591 39.2393C867.972 55.6871 942.243 80.1009 970.08 107.788C982.625 120.242 985.736 133.369 977.068 146.732C963.394 167.751 882.649 178.479 806.439 182.237C730.271 186.003 637.855 194.727 565.691 201.662C475.829 210.296 397.212 225.951 301.059 230.503C243.599 233.221 179.962 231.566 126.848 222.774C94.9251 217.478 68.602 209.855 48.7873 201.115Z" fill="#1A1F66"/>
                            </g>
                        </svg>
                    </div>
                    <div className="banner-3-health-grid-items-container">
                        <div className="banner-3-health-grid-item-vendor">
                            <div className="grid-3-health-icon-container">
                                <img className="banner-3-section-item-header-icon"  src={Binoculars}/>
                            </div>
                            <div className="grid-3-health-text">
                            Fornecedores são escolhidos pelo grupo para participar da demanda agrupada
                            </div>
                        </div>

                        <div className="banner-3-health-grid-item-vendor">
                            <div className="grid-3-health-icon-container">
                                <img className="banner-3-section-item-header-icon"  src={MoneyBaloon}/>
                            </div>
                            <div className="grid-3-health-text">
                                <b>Clientes convidam novos clientes</b> para o fornecedor, interessados nos benefícios da agregação de demanda
                            </div>
                        </div>

                        <div className="banner-3-health-grid-item-vendor">
                            <div className="grid-3-health-icon-container">
                                <img className="banner-3-section-item-header-icon"  src={Locker}/>
                            </div>
                              <div className="grid-3-health-text">
                              Construção de banco de dados do setor para <b>geração de inteligência</b> baseada no processamento desses dados unificados
                            </div>
                        </div>

                        <div className="banner-3-health-grid-item-vendor">
                            <div className="grid-3-health-icon-container">
                                <img className="banner-3-section-item-header-icon" src={HandHoldingUSD} />
                            </div>
                            <div className="grid-3-health-text">
                                <b>Vantagem competitiva</b> pela redução de custos na logística e vendas.
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default Banner3Health
