import styled from 'styled-components';

import { darken } from 'polished';

import { Button as RButton } from 'reactstrap';

const ButtonStyled = styled(RButton)`
  position: relative;
  
  min-width: 154px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  background-color: ${({ theme, background }) => background || theme.colours.lightgreen};
  box-shadow: ${({ background }) => background !== 'transparent' && '0px 5px 10px rgba(0, 0, 0, 0.2)'}; 
  color: ${({ color }) => color || 'white'};
  outline: none;
  overflow: hidden; 

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    color: ${({ color }) => color || 'white'};
    background-color: ${({ theme, background }) => darken(0.05, background || theme.colours.lightgreen)};
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform .5s, opacity 1s;
  }

  &:active::after {
    transform: scale(0, 0);
    opacity: .3;
    transition: 0s;
  }
`;

export default ButtonStyled;
