import React, { useState } from 'react'

import GeneralButton from "../../../components/health/GeneralButton";
import LogoPrincipal from "../../../assets/logo/logo-principal.svg";




const HeaderHealth= () => {

    const [isMenuOpened, setIsMenuOpened] = useState(false);


       
    const onHandleMenuClick = () => {
        setIsMenuOpened(!isMenuOpened);
       
    }


    return (
        <div className="header-health-container">
            <div className="left-side-health-header">
           
                <a className="header-anchor" href="/"><img className="header-logo" src={LogoPrincipal}/></a>
          
            </div>
            <div className="health-header-spacer">

            </div>
            <div className="right-side-health-header">

                     
            <div onClick={onHandleMenuClick} className="hamburger-menu-clickable">
                    {isMenuOpened ?(<div className="hamburger-close-button-dark">X</div>) : (
                    <div className="hamburger-menu">
                    <div className="hamburger-stripe-dark"></div> 
                    <div className="hamburger-stripe-dark"></div> 
                    <div className="hamburger-stripe-dark"></div> 
        
                    </div>
                    ) 
                }
            </div>

                <ul className="header-health-menu-list">
                    <li className="header-health-menu-item">
                        <GeneralButton background="#8CC63F" text="Cadastro" href="https://saude.suppli.us/inicio/cadastro" />
                    </li>
                    <li className="header-health-menu-item">
                        <GeneralButton withShadow={true} background="white" href="https://saude.suppli.us/inicio/login" color="#1A1F66" text="Login"/>
                    </li>
                </ul>
                
            </div>
            <div className={`header-bottom-container ${isMenuOpened ? "header-bottom-container-opened" : null} header-bottom-container-white`}>
                       
                        
                            <div className="container-left-anchors">
                                <a className={`header-anchor ${isMenuOpened ? "header-anchor-show " : null} header-anchor-black`} href="https://saude.suppli.us/cadastro">Cadastro</a>
                                <a className={`header-anchor ${isMenuOpened ? "header-anchor-show " : null} header-anchor-black`} href="https://saude.suppli.us/login">Login</a>
                            </div>
                          
                    </div>
        </div>
    )
}

export default HeaderHealth
