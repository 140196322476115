import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { chunk } from 'lodash';
import { Row, Col } from 'reactstrap';

import Title from './Title';
import CardIcon from '../CardIcon';

import { useIsMobileLayout } from '../../../../hooks/useWindowSize';

const Container = styled(Row)` 
  position: relative;
  box-sizing: border-box;
  background: ${({ backgroundColor }) => backgroundColor};  
  padding-top: calc(6.75rem + ${({ hasBorder }) => (hasBorder ? 48 : 0)}px);
  padding-bottom: 6.75rem;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  margin: 0;

  @media only screen and (max-device-width: 992px) {
    padding-left: 16px;
    padding-right: 16px;

    .title {
      text-align: center;
      margin-bottom: ${({ theme }) => 24 * theme.conversionRatio}px;
    }

    .content {
      margin-left: auto;
      margin-right: auto;

      .card-icon {
        margin-top: ${({ theme }) => 24 * theme.conversionRatio}px;
      }
    }
  }

  @media only screen and (max-device-width: 1200px) {
    padding-left: 80px;
    padding-right: 80px;
  }

  @media only screen and (min-device-width: 992px) {
    padding-left: 124px;
    padding-right: 124px;

    .cards-row {
      margin-top: 42px;
      padding-left: 8px;
      padding-right: 8px;
    }
  }

${({ hasBorder }) => hasBorder && `
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      border-top: 100px solid #f3f3f3;
      border-right: 98vw solid #fff;

      @media only screen and (max-device-width: 992px) {
        border-top: 80px solid #f3f3f3;
      }
    }
  `}
`;

const Image = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const Section = ({
  id,
  sections,
  reverse,
  hasBorder,
}) => {
  const isMobile = useIsMobileLayout();

  return (
    <Container
      id={id}
      reverse={reverse}
      hasBorder={hasBorder}
      backgroundColor={sections.backgroundColour}
    >
      {isMobile && (
        <Col className='title' md={12} lg={12}>
          <Title colour={sections.colour}>{sections.title}</Title>
        </Col>
      )}

      <Col md={12} lg={4}>
        <Image>
          <img alt='pc' width='100%' src={sections.image} />
        </Image>
      </Col>

      <Col className='content' md={10} lg={8}>
        <Row>
          {!isMobile && (
            <Col className='title' md={12} lg={12}>
              <Title colour={sections.colour}>{sections.title}</Title>
            </Col>
          )}

          {chunk(sections.cards, 1).map((row, i) => (
            <Row key={`row-${i}`} className='cards-row'  md={12} lg={12}>
              {row.map((col, j) => (
                // <Col key={`col-${j}`} className='card-icon' md={12} lg={6}>
                  <CardIcon
                    title={col.title}
                    description={col.description}
                    image={col.icon}
                  />
                // </Col>
              ))}
            </Row>
          ))}
        </Row>
      </Col>
    </Container>
  );
};

Section.propTypes = {
  reverse: PropTypes.bool,
  hasBorder: PropTypes.bool,
  id: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(PropTypes.shape({
    backgroundColour: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    colour: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    cards: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })).isRequired,
  })).isRequired,
};

Section.defaultProps = {
  reverse: false,
  hasBorder: false,
};

export default Section;
