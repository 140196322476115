import React from 'react';

import PropTypes from 'prop-types';

import styled, { withTheme, css } from 'styled-components';

import { Row, Col } from 'reactstrap';

import bgBlur from '../../assets/images/bg-blur.jpg';
import woman from '../../assets/images/supplius-woman.png';
import tela from '../../assets/images/tela-frente.png';
import telaBlur from '../../assets/images/tela-tras.png';

import Typography from '../Typography/Typography';
import Button from '../Button';

import { useIsMobileLayout } from '../../../../hooks/useWindowSize';
import linkTo from '../../../../config/link-supplius';

const AngleDiv = styled.div`
  width: 0;
  border-bottom: 926px solid #1a1f66;
  border-right: 120px solid transparent;

  @media only screen and (max-device-width: 992px) {
  display: none;
  } 
`;

const SolidBg = styled(Col)`   
  position: relative;
  background: ${({ background }) => background};  
  padding: 0;
  margin: 0;

`;

const BlurBg = styled(Col)`
  position: relative;   
  background-image: url(${({ image }) => image});
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;  
  padding: 0;
  margin: 0;

  display: flex;

  @media only screen and (max-device-width: 992px) {  
    z-index: 10;
    justify-content: center;
  } 
`;

const btnMobile = css`
  width: 550px;
  height: 120px;
  border-radius: 20px;
  font-size: 2rem;
  width: 575px;
`;

const TextBox = styled.div`  
  padding: 0 12%;
  padding-top: 185px;

  display: flex;
  flex-direction: column;  

  .sub-title{margin-top: 95px;}
  .button-box{margin-top: 85px;}

  @media only screen and (max-device-width: 992px) {
    padding: 0;
    margin: 0 auto;
    padding-top: 272px;

    .sub-title{margin-top: 42px;}
    .button-box{ margin: 45px auto; }

    span{ 
    text-align: center;
    }

    .btn-mobile{ 
      ${useIsMobileLayout && btnMobile}
    }
  }
`;

const ImgWoman = styled.img`
  width: 40vw;
  z-index: 10; 
  max-width: 760px;
  max-height: 926px;

  @media only screen and (max-device-width: 992px) {
    position: static; 
    width: 100vw;
    z-index: 20; 
    margin-top: -22%;

    
  }
`;

const ImgScreen = styled.img`
  width: 80vw; 

  max-width: 563px;
  max-height: 347px;

  position: absolute; 
  z-index: 20;  
  bottom: 220px;
  left: 84%; 

  @media only screen and (max-device-width: 992px) {
    position: static;
    z-index: 10; 
    margin: 15px auto;
    margin-top: -180px;
  }
`;

const ImgScreenBlur = styled.img`
  width: 70vw; 

  max-width: 397px;
  max-height: 230px;

  position: absolute; 
  top: 200px;
  left: 97%; 
  z-index: 5;

  @media only screen and (max-device-width: 992px) {
    position: static;
    margin: 0 auto;
  }
`;

const FirstFold = ({ theme }) => {
  const { colours: { lightorange, white, darkblue } } = theme;
  const isMobile = useIsMobileLayout();

  return (
    <Row fluid className='p-0 m-0'>
      <SolidBg md='12' lg='6' background={darkblue}>
        <TextBox md='12' lg='12'>
          <Typography
            tag={isMobile ? 'h3m' : 'h1'}
            fontWeight='500'
            colour={white}
          >
            Melhor
            {' '}
            <strong>para comprar,</strong>
            <br />
            melhor
            {' '}
            <strong>para vender.</strong>
          </Typography>
          <Typography
            className='sub-title'
            tag={isMobile ? 'pmd' : 'h4'}
            colour={white}
          >
            {isMobile && (
              <>
                Utilize a plataforma Supplius para
                <br />
                <strong>
                  aumentar vendas e reduzir custos.
                </strong>
              </>
            )}

            {!isMobile && (
              <>
                Revolucione as suas negociações em construções
                {' '}
                <strong>
                  ultilizando o algoritmo Supplius
                  para aumentar vendas e reduzir custos.
                </strong>
              </>
            )}
          </Typography>

          <div className='button-box'>
            <a href={linkTo.cadastro}>
              <Button
                className='btn-mobile'
                color={white}
                background={lightorange}
              >
                Experimente
              </Button>
            </a>
          </div>
         
          <ImgScreen src={tela} />
        </TextBox>
      </SolidBg>
      <BlurBg md='12' lg='6' image={bgBlur}>
        <AngleDiv />
        <ImgWoman src={woman} />
      </BlurBg>
    </Row>
  );
};


FirstFold.propTypes = {
  theme: PropTypes.object.isRequired,
  colours: PropTypes.object,
  lightorange: PropTypes.string,
  white: PropTypes.string,
  darkblue: PropTypes.string,
};

FirstFold.defaultProps = {
  colours: {},
  lightorange: 'white',
  white: 'white',
  darkblue: 'white',
};

export default withTheme(FirstFold);
