import React from 'react'
import styled from "styled-components";
import {MedicalCross } from "@styled-icons/open-iconic/MedicalCross"
import { Engineering } from "@styled-icons/material-rounded/Engineering"
import { Food } from "@styled-icons/fluentui-system-filled/Food"
import { NavLink } from 'react-router-dom';
import {Wrench} from "@styled-icons/fluentui-system-filled/Wrench"
const Banner1Sectors = () => {
    return (
        <div className="banner-1-sectors-container">
            <div className="sector-title">
                ESCOLHA O SETOR
            </div>
            <div className="sector-items-row">
                <div className="sector-item-container">
                    <NavLink to="/construcao" className="sector-item-icon-circle">
        
                        <EngineerIcon/>
                    </NavLink>
                    <p className="sector-item-title">
                        Construção
                    </p>
                </div>
                <div className="sector-item-container">
                    <NavLink to="/saude" className="sector-item-icon-circle">
                        <MedicalIcon className="sector-item-icon"/>
                    </NavLink>
                    <p className="sector-item-title">
                        Saúde
                    </p>
                </div>
                <div className="sector-item-container">
                    <a href='https://autopecas.suppli.us' className="sector-item-icon-circle">
                        <WrenchIcon className="sector-item-icon"/>
                    </a>
                    <p className="sector-item-title">
                        Autopeças
                    </p>
                </div>
                {/* <div className="sector-item-container">
                    <div className="sector-item-icon-circle">
                        <FoodIcon/>
                    </div>
                    <p className="sector-item-title">
                        Alimentação
                    </p>
                </div> */}
            </div>
            
        </div>
    )
}

const MedicalIcon = styled(MedicalCross)`
    color: rgba(0,0,0,0.7);
    height: 30px;
    width: 30x
    &:hover {
        font-size: 27px
        height: 20px;
        width: 20x
        size: 10px
    }
    @media ( max-width: 700px) {
        height: 20px;
        width: 20px;
       
    }
    
`

const FoodIcon = styled(Food)`
color: rgba(0,0,0,0.7);
height: 40px;
width: 40x
&:hover {
    font-size: 27px
    height: 20px;
    width: 20x
    size: 10px
}
@media ( max-width: 700px) {
    height: 24px;
    width: 24px;
   
}
    
`
const EngineerIcon = styled(Engineering)`
color: rgba(0,0,0,0.7);
height: 40px;
width: 40x
&:hover {
    font-size: 27px
    height: 20px;
    width: 20x
    size: 10px
}
@media ( max-width: 700px) {
    height: 26px;
    width: 26px;
   
}
    
`

const WrenchIcon = styled(Wrench)`
    color: rgba(0,0,0,0.7);
    height: 30px;
    width: 30x
    &:hover {
        font-size: 27px
        height: 20px;
        width: 20x
        size: 10px
    }
    @media ( max-width: 700px) {
        height: 20px;
        width: 20px;
       
    }
    
`



export default Banner1Sectors
