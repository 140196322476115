import React from 'react';
import { Container } from 'reactstrap';
import DividerIcon from '../../components/DividerIcon';
import Navbar from '../../components/Navbar/Navbar';

import FirstFold from '../../components/FirstFold/FirstFold';
import SecondFold from '../../components/SecondFold/SecondFold';
import ThirdFold from '../../components/ThirdFold/ThirdFold';

import { ThemeProvider } from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';

import GlobalStyle from '../../style/global';
import theme from '../../../../config/theme';
const MainConstruction = () => (
  <>
  <GlobalStyle />
  <ThemeProvider theme={theme}>
  <Container
    fluid
    className='m-0 p-0'
  >
    <Navbar />
    <FirstFold />
    <DividerIcon />
    <SecondFold />
    <ThirdFold />
  </Container>
  </ThemeProvider>
  </>
);
export default MainConstruction;
